<template>
  <div class="contents">
    <div class="container-fluid">
      <div class="profile-content mb-50">
        <div class="row">
          <div class="col-lg-12 mb-2 sticky">
            <div
              class="breadcrumb-main p-3"
              style="background-color: #f4f5f7 !important"
            >
              <h4 class="text-capitalize breadcrumb-title">
                Documentation Approval
                <ul class="atbd-breadcrumb nav">
                  <li class="atbd-breadcrumb__item">
                    <router-link to="/">
                      <span class="la la-home"></span>
                    </router-link>
                    <span class="breadcrumb__seperator">
                      <span class="la la-slash"></span>
                    </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                    <span class="bread-item" style="color: #9299b8"
                      >Approval</span
                    >
                    <span class="breadcrumb__seperator">
                      <span class="la la-slash"></span>
                    </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                    <span class="bread-item">Document</span>
                  </li>
                </ul>
              </h4>
            </div>
          </div>
          <div class="container-fluid overlay-clipped">
            <LoaderOverlay
              :active="true"
              v-if="!asyncLoader && loadingDocument"
              :zIndex="99"
              position="absolute"
              style="height: 100%"
            />
            <no-content v-if="noData" />
            <div class="row" v-if="documents.length">
              <div class="col-12">
                <div
                  class="
                    userDatatable
                    orderDatatable
                    sellerDatatable
                    global-shadow
                    border
                    mb-30
                    py-30
                    px-sm-30 px-20
                    bg-white
                    radius-xl
                    w-100
                  "
                >
                  <div class="table-responsive">
                    <table class="table mb-0 table-borderless border-0">
                      <thead>
                        <tr class="userDatatable-header">
                          <th scope="col-4">
                            <span class="userDatatable-title">No</span>
                          </th>
                          <th scope="col-4">
                            <span class="userDatatable-title">Name</span>
                          </th>
                          <th scope="col-4">
                            <span class="userDatatable-title">Username</span>
                          </th>
                          <th scope="col-4">
                            <span class="userDatatable-title">Document</span>
                          </th>
                          <th scope="col-4">
                            <span class="userDatatable-title">Type</span>
                          </th>
                          <th scope="col-4">
                            <span class="userDatatable-title">Note</span>
                          </th>
                          <th scope="col-2">
                            <span class="userDatatable-title">Status</span>
                          </th>
                          <th scope="col-2">
                            <span class="userDatatable-title float-right"
                              >Action</span
                            >
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="(doc, k) in documents" :key="k">
                          <td>
                            <div class="orderDatatable-title">
                              {{ paginationData.offset + k + 1 }}
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="orderDatatable-title">
                                <!-- <p class="d-block mb-0">
                                  {{ doc.ulid }}
                                </p> -->
                                <router-link
                                  :to="{
                                    name: 'officers-profile',
                                    params: { officer: { ulid: doc.ulid } },
                                  }"
                                >
                                  {{ users[doc.ulid].name }}
                                </router-link>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="orderDatatable-title">
                                <!-- <p class="d-block mb-0">
                                  {{ doc.ulid }}
                                </p> -->
                                <router-link
                                  :to="{
                                    name: 'officers-profile',
                                    params: { officer: { ulid: doc.ulid } },
                                  }"
                                >
                                  @{{ users[doc.ulid].username }}
                                </router-link>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div class="d-flex align-items-center">
                              <div class="orderDatatable-title">
                                <p class="d-block mb-0">
                                  {{ doc.name }}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="orderDatatable-title">
                                <p class="d-block mb-0">
                                  {{ doc.tag }}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="orderDatatable-title">
                                <p v-if="doc.note" class="d-block mb-0">
                                  {{ doc.note }}
                                </p>
                                <p v-else class="d-block mb-0">None</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div
                              v-if="doc.status == 1"
                              class="orderDatatable-title"
                            >
                              <span
                                class="atbd-tag tag-success tag-transparented"
                                >Approved</span
                              >
                            </div>
                            <div
                              v-if="doc.status == 0"
                              class="orderDatatable-title"
                            >
                              <span
                                class="atbd-tag tag-warning tag-transparented"
                                >Pending</span
                              >
                            </div>
                            <div
                              v-if="doc.status == -1"
                              class="orderDatatable-title"
                            >
                              <span
                                class="atbd-tag tag-danger tag-transparented"
                                >Declined</span
                              >
                            </div>
                          </td>
                          <td>
                            <ul
                              class="
                                orderDatatable_actions
                                mb-0
                                d-flex
                                flex-wrap
                                float-right
                              "
                            >
                              <li>
                                <!-- <a
                                  :href="
                                    `${envVariables.VUE_APP_STORAGE_BUCKET}${doc.src}`
                                  "
                                  target="_blank"
                                  class="remove"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="View"
                                >
                                  <i class="las la-eye"></i>
                                </a> -->
                                <a class="link" @click="previewDocument(doc)">
                                  <i class="las la-eye"></i>
                                </a>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- ends: col-12 -->
            </div>
          </div>
            <Pagination
              :url="documentUrl"
              :size="size"
              @pageChange="pageChange"
              @loading="loading"
            />
        </div>
      </div>
    </div>

    <DocumentPreview
      :document="selectedDocument"
      @docStatus="refreshDocTable"
    />
  </div>
</template>

<script>
// import Paginate from "vuejs-paginate";
import { authComputed } from "@/vuex/helpers.js";
import DocumentPreview from "@/components/DocumentPreview.vue";
import Pagination from "@/components/Pagination.vue";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import NoContent from "@/components/NoContent.vue";
import { mapState } from "vuex";
export default {
  components: {
    // Paginate,
    DocumentPreview,
    Pagination,
    NoContent,
    LoaderOverlay,
  },
  data() {
    return {
      isLoading: false,
      totalPage: 0,
      size: 10,
      selectedDocument: "",
      documents: [],
      users: [],
      loadingDocument: false,
      noData: false,
      documentUrl: "/v1/office/adjudicate/account/document",
      paginationData: "",
    };
  },
  metaInfo: {
    title: "Greater Favour",
    titleTemplate: "%s | Document Verification",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  computed: {
    ...mapState(["asyncLoader"]),
    ...authComputed,
  },
  mounted() {
    // this.$on('docstatus', (data)=>console.log('listen on', data))
    if (this.loggedIn == false) {
      this.$router.push({ path: "/login" });
    }
  },
  methods: {
    loading(status) {
      this.loadingDocument = status;
    },
    previewDocument(doc) {
      this.selectedDocument = "";
      setTimeout(() => {
        this.selectedDocument = doc;
      }, 1000);
    },
    refreshDocTable() {
      // this.$forceUpdate()
      // console.log('selected document', data)
      this.selectedDocument.status = 1;
      // this.documents
    },
    pageChange(dataSet, paginationData, response) {
      this.documents = dataSet;
      this.paginationData = paginationData;
        this.users = !response.error && response.info.users;
      if (this.documents.length == 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    },
  },
};
</script>
