var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contents"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"profile-content mb-50"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 mb-2 sticky"},[_c('div',{staticClass:"breadcrumb-main p-3",staticStyle:{"background-color":"#f4f5f7 !important"}},[_c('h4',{staticClass:"text-capitalize breadcrumb-title"},[_vm._v(" Documentation Approval "),_c('ul',{staticClass:"atbd-breadcrumb nav"},[_c('li',{staticClass:"atbd-breadcrumb__item"},[_c('router-link',{attrs:{"to":"/"}},[_c('span',{staticClass:"la la-home"})]),_vm._m(0)],1),_vm._m(1),_vm._m(2)])])])]),_c('div',{staticClass:"container-fluid overlay-clipped"},[(!_vm.asyncLoader && _vm.loadingDocument)?_c('LoaderOverlay',{staticStyle:{"height":"100%"},attrs:{"active":true,"zIndex":99,"position":"absolute"}}):_vm._e(),(_vm.noData)?_c('no-content'):_vm._e(),(_vm.documents.length)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"userDatatable orderDatatable sellerDatatable global-shadow border mb-30 py-30 px-sm-30 px-20 bg-white radius-xl w-100"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table mb-0 table-borderless border-0"},[_vm._m(3),_c('tbody',_vm._l((_vm.documents),function(doc,k){return _c('tr',{key:k},[_c('td',[_c('div',{staticClass:"orderDatatable-title"},[_vm._v(" "+_vm._s(_vm.paginationData.offset + k + 1)+" ")])]),_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"orderDatatable-title"},[_c('router-link',{attrs:{"to":{
                                  name: 'officers-profile',
                                  params: { officer: { ulid: doc.ulid } },
                                }}},[_vm._v(" "+_vm._s(_vm.users[doc.ulid].name)+" ")])],1)])]),_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"orderDatatable-title"},[_c('router-link',{attrs:{"to":{
                                  name: 'officers-profile',
                                  params: { officer: { ulid: doc.ulid } },
                                }}},[_vm._v(" @"+_vm._s(_vm.users[doc.ulid].username)+" ")])],1)])]),_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"orderDatatable-title"},[_c('p',{staticClass:"d-block mb-0"},[_vm._v(" "+_vm._s(doc.name)+" ")])])])]),_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"orderDatatable-title"},[_c('p',{staticClass:"d-block mb-0"},[_vm._v(" "+_vm._s(doc.tag)+" ")])])])]),_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"orderDatatable-title"},[(doc.note)?_c('p',{staticClass:"d-block mb-0"},[_vm._v(" "+_vm._s(doc.note)+" ")]):_c('p',{staticClass:"d-block mb-0"},[_vm._v("None")])])])]),_c('td',[(doc.status == 1)?_c('div',{staticClass:"orderDatatable-title"},[_c('span',{staticClass:"atbd-tag tag-success tag-transparented"},[_vm._v("Approved")])]):_vm._e(),(doc.status == 0)?_c('div',{staticClass:"orderDatatable-title"},[_c('span',{staticClass:"atbd-tag tag-warning tag-transparented"},[_vm._v("Pending")])]):_vm._e(),(doc.status == -1)?_c('div',{staticClass:"orderDatatable-title"},[_c('span',{staticClass:"atbd-tag tag-danger tag-transparented"},[_vm._v("Declined")])]):_vm._e()]),_c('td',[_c('ul',{staticClass:"orderDatatable_actions mb-0 d-flex flex-wrap float-right"},[_c('li',[_c('a',{staticClass:"link",on:{"click":function($event){return _vm.previewDocument(doc)}}},[_c('i',{staticClass:"las la-eye"})])])])])])}),0)])])])])]):_vm._e()],1),_c('Pagination',{attrs:{"url":_vm.documentUrl,"size":_vm.size},on:{"pageChange":_vm.pageChange,"loading":_vm.loading}})],1)])]),_c('DocumentPreview',{attrs:{"document":_vm.selectedDocument},on:{"docStatus":_vm.refreshDocTable}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"breadcrumb__seperator"},[_c('span',{staticClass:"la la-slash"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"atbd-breadcrumb__item"},[_c('span',{staticClass:"bread-item",staticStyle:{"color":"#9299b8"}},[_vm._v("Approval")]),_c('span',{staticClass:"breadcrumb__seperator"},[_c('span',{staticClass:"la la-slash"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"atbd-breadcrumb__item"},[_c('span',{staticClass:"bread-item"},[_vm._v("Document")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"userDatatable-header"},[_c('th',{attrs:{"scope":"col-4"}},[_c('span',{staticClass:"userDatatable-title"},[_vm._v("No")])]),_c('th',{attrs:{"scope":"col-4"}},[_c('span',{staticClass:"userDatatable-title"},[_vm._v("Name")])]),_c('th',{attrs:{"scope":"col-4"}},[_c('span',{staticClass:"userDatatable-title"},[_vm._v("Username")])]),_c('th',{attrs:{"scope":"col-4"}},[_c('span',{staticClass:"userDatatable-title"},[_vm._v("Document")])]),_c('th',{attrs:{"scope":"col-4"}},[_c('span',{staticClass:"userDatatable-title"},[_vm._v("Type")])]),_c('th',{attrs:{"scope":"col-4"}},[_c('span',{staticClass:"userDatatable-title"},[_vm._v("Note")])]),_c('th',{attrs:{"scope":"col-2"}},[_c('span',{staticClass:"userDatatable-title"},[_vm._v("Status")])]),_c('th',{attrs:{"scope":"col-2"}},[_c('span',{staticClass:"userDatatable-title float-right"},[_vm._v("Action")])])])])
}]

export { render, staticRenderFns }